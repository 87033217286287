<template>
    <div style="margin:10px 10px 0 10px;background-color: #EFF1F4;height: 100vh">
        <!--搜索-->
        <div class="el-card is-always-shadow" style="padding:20px 0 0 20px;margin-top: 10px;border-radius: 0">
            <el-form ref="searchForm" :model="searchForm" size="mini" inline>
                <el-form-item label="社区" prop="name" v-if="hrole<11">
                    <el-select v-model="searchForm.hospital" filterable placeholder="请选择" clearable>
                        <el-option
                                v-for="item in HospitalAllData"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备名称" prop="name">
                    <el-select v-model="searchForm.device_id" filterable placeholder="请选择" clearable
                    >
                        <el-option v-for="item in deviceData" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="手机号" prop="name">
                    <el-input v-model="searchForm.phone" maxlength="11" clearable/>
                </el-form-item>
                <el-form-item label="绑定时间" prop="name">
                    <el-date-picker value-format="yyyy-MM-dd" v-model="searchForm.create_time" type="daterange"

                                    range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>

                <el-button type="primary" size="mini" @click="onSearch" icon="el-icon-search">查询
                </el-button>
            </el-form>
        </div>
        <div style="margin-top: 10px;height:calc(100vh - 165px);background-color: #ffffff;">
            <!--数据-->
            <div style="padding: 10px">
                <el-table :row-style="{height:0+'px'}" :cell-style="{padding:5+'px'}" :data="tableData" border
                          style="width: 100%;"
                          header-cell-style="background-color: #f5f5f5; padding:5px 0; ">
                    <el-table-column prop="id" label="序号" width="66" align="center">
                    </el-table-column>
                    <el-table-column prop="name" label="绑定用户" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.name:'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="用户名ID" align="center">
                        <template slot-scope="scope">
                            {{scope.row.user?scope.row.user.id:'/'}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="user.mobile" label="手机号" align="center"></el-table-column>

                    <el-table-column prop="create_time" label="绑定时间" align="center"></el-table-column>

                    <el-table-column v-if="hrole<11" prop="hospital_name" label="社区" align="center"
                                     min-width="150"></el-table-column>
                    <el-table-column prop="device_name" label="设备名称" align="center"></el-table-column>
                    <el-table-column prop="client_id" label="绑定码" align="center"></el-table-column>
                    <el-table-column prop="date" label="操作" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="userData(scope.row)">查看数据</el-button>

                        </template>
                    </el-table-column>

                </el-table>
            </div>
            <!--分页-->
            <div style="margin-top: 25px;text-align: center">
                <el-pagination layout="total, sizes, prev, pager, next" :current-page="page"
                               :page-sizes="[10, 20, 50]" :page-size="pagesize"
                               :total="total" @size-change="handleSizeChange"
                               @current-change="handleCurrentChange">
                </el-pagination>
            </div>
        </div>
    </div>

</template>
<script>
    import request from "../../plugins/axios";

    export default {
        data() {
            return {
                deviceData: [
                    {
                        value: '1',
                        label: '血糖血压测试仪'
                    },
                    {
                        value: '2',
                        label: '蓝牙通话手表'
                    },
                    {
                        value: '3',
                        label: '健康体脂秤'
                    },
                ],
                hrole: '',
                selectKey: [],

                //用户管理
                searchForm: {
                    device_id: '',
                    hospital: '',
                    phone: '',
                    create_time: ''
                },
                HospitalAllData: [],//所有社区
                RoleAllData: [],//所有角色
                user_form: {
                    curd: '',
                    mobile: '',
                    info: '',
                    id: '',
                },
                userDialog: false,
                page: 1,
                total: 0,
                pagesize: 10,
                tableData: [],


            };
        },
        components: {},
        created() {
            let that = this
            let uid = that.getQueryString('uid')
            if (uid && uid > 0) {
                that.searchForm.phone = localStorage.getItem('usphone')
            }
            let ua = JSON.parse(localStorage.getItem('ua'))
            if (ua.hospital > 0) {

                that.searchForm.hospital = ua.hospital
            }
        },
        mounted() {
            let that = this
            that.getUserData();
            that.getHospitalAllData();
            this.hrole = localStorage.getItem('hrole')
            let ua = JSON.parse(localStorage.getItem('ua'))
            if (ua.hospital>0) {
                that.searchForm.hospital = ua.hospital
            }
        },
        methods: {
            //获取url参数
            getQueryString(name) {
                var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
                var r = window.location.search.substr(1).match(reg);
                if (r != null) {
                    return unescape(r[2]);
                }
                return null;
            },
            //用户数据
            userData(row) {
                localStorage.setItem('usphone', row.user.mobile)
                localStorage.setItem('usdevice', row.device_name)
                window.open(this.$router.resolve({path: "/admin/data?uid=1"}).href, "_blank");
            },
            //用户开始
            getUserData(page) {
                let that = this
                let data = that.searchForm
                data.pagesize = that.pagesize
                data.page = page ? page : 1;
                data.hp = 1000
                request({
                    url: "/hospital/News/hospital_device",
                    data: data,
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.tableData = ret.data.list
                        that.total = ret.data.total
                    }
                });
            },
            //获取所有社区
            getHospitalAllData() {
                let that = this
                request({
                    url: "/hospital/News/hospital_list",
                    data: {pageSize: 1000, page: 1, hp: 1000},
                }).then((ret) => {
                    if (ret.code == 1) {
                        this.HospitalAllData = ret.data.list
                    }
                });
            },
            //用户查询
            onSearch() {
                let that = this.getUserData();
            },

            handleSizeChange(pagesize) {
                this.pagesize = pagesize;
                this.getUserData(1);
            },
            handleCurrentChange(page) {
                this.getUserData(page);
            }
        },
    };
</script>